@import url(https://fonts.googleapis.com/css?family=Montserrat&display=swap);
body {
  margin: 0;
  font-family: "Montserrat", "Century Gothic", "Helvetica", "Arial", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #F6FBFF;
}

.dx-datagrid {
  font-family: "Montserrat", "Century Gothic", "Helvetica", "Arial", sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#root {
  width: 100%;
  height: 100%;
  position: relative;
  font-size: 14px;
  background: #F6FBFF;
}

.MuiDrawer-root{
  z-index: 1200 !important;
}

.sidebar_menuitem{
  height: 30px;
  font-size: 14px;
  font-family: "Montserrat", "Century Gothic", "Helvetica", "Arial", sans-serif;
}

.sidebar_menuitem .menuitem_icon{
  float: left;
}

.sidebar_menuitem .menuitem_icon svg{
  width: 24px;
  height: 24px;
}

.sidebar_menuitem .menuitem_text{
  float: left;
  padding-left: 10px;
  line-height: 24px;
}

#dashboard_container {
  width: 100%;
  text-align: center;
  box-sizing: border-box;
}

.card {
  max-width: 500px;
  position: relative;
  min-height: 330px;
  cursor: pointer;
  background: #FFFFFF;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.2), 0px 2px 1px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  margin: 8px;
}

@media (max-width: 1100px) {
  .card {
    width: 90%;
  }
}

@media (min-width: 1100px) {
  .card {
    width: 45%;
  }
}

@media (min-width: 1600px) {
  .card {
    width: 30%;
  }
}

.cardHeader {
  display: block;
  width: 100%;
  text-align: center;
  padding: 16px;
  color: #00334E;
  border-top: 1px solid #D0101B;
  box-sizing: border-box;
  text-decoration: none;
  flex: 0 0 auto;
}

.cardBody {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  padding: 16px;
  box-sizing: border-box;
  flex: 1 0 auto;
}

.dashboard-flow {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.request-program-by-year {
  border-collapse: collapse;
  font-size: 12px;
}

.request-program-by-year td {
  padding: 6px;
}

.request-program-by-year td.request-program-by-year__year {
  padding-left: 12px;
  padding-right: 12px;
}

.request-program-by-year thead td {
  color: #1db2f5;
  font-weight: bold;
}

.request-program-by-year thead td.request-program-by-year__name {
  text-align: left;
}

.request-program-by-year tbody td {
  border-top: thin solid #1db2f5;
}

.request-program-by-year tbody td.request-program-by-year__name {
  text-align: right;
}

